// $main-font:'Roboto', sans-serif;

$medium-grey: #a8a8a8;
$light-grey: #F2F2F2;
$dark-grey: #313537;
$common-blue: #0078A9;
$light-blue: #D6DEEE;
$dark-blue: #173654;
$color-error: #f53c3ce6;
$color-success: #33973b;


