// Mixin to manage responsive breakpoints 
// @param {String} $breakpoint - Breakpoint name
// @require $breakpoints
@mixin respond-to($breakpoint) {
	// If the key exists in the map
	@if map-has-key($breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media #{inspect(map-get($breakpoints, $breakpoint))} {
			@content;
		}

	} @else {

    	@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        	+ "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}