@import './mixins.scss';
@import './breakpoints.scss';
@import './utils.scss';
@import './animations.scss';


body {
    margin: 0;
    font-size: 16px;

}
*, :after, :before {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

p {
    margin: 0;
    p + p {
        margin-top: 10px;
    }
}

h1, h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0;
    }

.App {
    overflow-x: hidden;
    font-family: 'Nunito Sans', sans-serif;
}