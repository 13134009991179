@import '../../scss/breakpoints.scss';
@import '../../scss/mixins.scss';

.cmp-main {
    max-width: 80%;
    margin: auto;
    margin-bottom: 50px;
    @include respond-to('medium') {
        min-height: 100vh;
        margin-top:40px
    }

}